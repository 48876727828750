import Chart from "chart.js/auto";
import 'chartjs-adapter-date-fns';
import {de} from 'date-fns/locale';
import format from 'date-fns/format';


function dateToInputString(date: Date): string {
    return format(date, "yyyy-MM-dd");
}

function inputStringToDate(dateString: string): Date {
    const date = new Date(dateString);
    date.setHours(0, 0, 0, 0);

    return date;
}

function getDaySum(rawData: string): string {
    const data = rawData.split("\n");
    const sumRow = data[2];
    const splittedRow = sumRow.split(";");
    return Number(splittedRow[4]).toFixed(2);
}

function convertData(rawData: string, baseDate: Date) {
    return rawData.split("\n").reduce<Array<{ x: string, y: number }>>((acc, cur) => {
        const regex = /^(\d\d):(\d\d):(\d\d);.*;(.*?);/;
        const result = cur.match(regex);
        if (result) {
            const res: { x: string, y: number } = {x: "", y: 0};
            const date = new Date(baseDate);
            date.setHours(Number(result[1]));
            date.setMinutes(Number(result[2]));
            date.setSeconds(Number(result[3]));
            res.x = date.toISOString();
            res.y = Number(result[4]);
            acc.push(res);
        }
        return acc;
    }, []);
}

async function fetchData(type: string, date: Date, label: string) {
    const filename = dateToInputString(date);
    const response = await fetch(`https://solar.c-reimer.de/data/fetch.php?src=${type}&date=${filename}`);
    const rawData = await response.text();

    return {data: convertData(rawData, date), label: label + " (" + getDaySum(rawData) + "kWh)"};
}

async function updateData(date: Date, chart: Chart<'line', unknown, unknown>) {
    const datas = await Promise.all([
        fetchData("data15", date, "Buchenstraße 15"),
        fetchData("data20", date, "Buchenstraße 20"),
        fetchData("data", date, "Drosselweg 5")
    ])

    chart.data.datasets = [
        {pointStyle: false, cubicInterpolationMode: 'monotone', ...datas[0]},
        {pointStyle: false, cubicInterpolationMode: 'monotone', ...datas[1]},
        {pointStyle: false, cubicInterpolationMode: 'monotone', ...datas[2]}
    ];

    chart.update();
}

(async function () {
    const targetNode = document.getElementById("chart") as HTMLCanvasElement;
    const dateInput = document.getElementById("dateInput") as HTMLInputElement;
    const dateString = new URLSearchParams(window.location.search).get("date");
    const now = dateString ? new Date(dateString) : new Date();
    now.setHours(0, 0, 0, 0);
    dateInput.value = dateToInputString(now);
    dateInput.max = dateToInputString(now);

    const chart = new Chart(targetNode, {
        type: "line",
        data: {datasets: []},
        options: {
            animation: false,
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: {
                    type: "time",
                    time: {
                        displayFormats: {hour: 'HH:mm', minute: 'HH:mm'},
                        tooltipFormat: 'HH:mm'
                    },
                    adapters: {date: {locale: de}},
                    ticks: {source: "data"}
                }
            },
            locale: "de-DE"
        },
    })

    dateInput.addEventListener("change", (event) => {
        const value = (event.target as HTMLInputElement).value;
        const date = inputStringToDate(value);
        updateData(date, chart).catch(console.error);
        history.pushState({date: inputStringToDate(value)}, "", "?date=" + value)
    })

    window.addEventListener("popstate", (event) => {
        console.log(event.state);
        updateData(event.state.date as Date, chart);
    })

    updateData(now, chart).catch(console.error);
})();

console.log("HELLO")